
// @ts-nocheck
import locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_en_json from "../i18n/en.json";
import locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_tr_json from "../i18n/tr.json";
import locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_ar_json from "../i18n/ar.json";


export const localeCodes =  [
  "en",
  "tr",
  "ar"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/en.json", load: () => Promise.resolve(locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_en_json), cache: true }],
  "tr": [{ key: "../i18n/tr.json", load: () => Promise.resolve(locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_tr_json), cache: true }],
  "ar": [{ key: "../i18n/ar.json", load: () => Promise.resolve(locale__home_runner_atlassian_bitbucket_pipelines_runner_temp_d914fb0b_0e8c_5f2d_9956_7686276e8459_build_i18n_ar_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "dir": "ltr",
      "language": "en-US",
      "files": [
        "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/en.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "dir": "ltr",
      "language": "tr-TR",
      "files": [
        "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/tr.json"
      ]
    },
    {
      "code": "ar",
      "name": "العربية",
      "dir": "rtl",
      "language": "ar-EG",
      "files": [
        "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/ar.json"
      ]
    }
  ],
  "defaultLocale": "ar",
  "defaultDirection": "rtl",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./i18n",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "http://localhost:3000",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "dir": "ltr",
    "language": "en-US",
    "files": [
      {
        "path": "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/en.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Türkçe",
    "dir": "ltr",
    "language": "tr-TR",
    "files": [
      {
        "path": "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/tr.json"
      }
    ]
  },
  {
    "code": "ar",
    "name": "العربية",
    "dir": "rtl",
    "language": "ar-EG",
    "files": [
      {
        "path": "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/i18n/ar.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
