import { defineStore } from 'pinia'
import getAllRoutes from './AppApiRoutes'
import type { Ad, TypesAnnouncements } from '~~/composables/useMenuModel'
import { TypeAdvertEnum } from '~~/composables/useMenuModel'

export const useAnnouncementStore = defineStore('announcements', {
  state: (): {
    announcements: Ad[]
    typesAnnouncements: TypesAnnouncements
    errors: any
  } => ({
    announcements: [],
    typesAnnouncements: {},
    errors: null
  }),

  getters: {
    getAnnouncements: (state) => {
      return state.typesAnnouncements
    },
    getErrors: (state) => {
      return state.errors
    }
  },

  actions: {
    clearAnnouncements() {
      this.announcements = []
      this.typesAnnouncements = {}
    },
    async loadAnnouncements() {
      const { adModel } = useMenuModel()
      if (!this.announcements.length) {
        const { data: itemsAds, error: errorAds } = await useBasicFetch(
          getAllRoutes().announcementRoutes.getAnnouncementsByServer,
          {
            query: {
              domain: useDomainHeader()
            },

            transform: (data: SuccessResponse<Ad[]>) => {
              return data.data.map(adModel)
            }
          }
        )
        if (itemsAds.value?.length) {
          this.announcements = itemsAds.value
          this.typesAnnouncements = getAnnouncementsSortedByType(
            this.announcements
          )
        } else if (errorAds.value != null) {
          this.errors = errorAds.value
        }
      }
      return this.typesAnnouncements
    },
    goToRelatedAd(ad: Ad) {
      const router = useRouter()
      const localePath = useLocalePath()

      if (ad.related_id) {
        if (ad.related_type === 'App\\Models\\Material') {
          router.push(
            localePath({
              name: 'materials-id',
              params: { id: ad.related_id }
            })
          )
        } else if (ad.related_type === 'App\\Models\\Store') {
          router.push(
            localePath({
              name: 'stores-id',
              params: { id: ad.related_id }
            })
          )
        }
      } else if (ad.link) {
        window.open(ad.link, '_blank')?.focus()
      }
    }
  }
})
function getAdvertType(location: number) {
  switch (location) {
    case 11:
      return TypeAdvertEnum.PrimaryDelivery
    case 12:
      return TypeAdvertEnum.SecondaryDelivery
    case 13:
      return TypeAdvertEnum.CrossMaterialDelivery
    case 14:
      return TypeAdvertEnum.CrossMaterialDelivery

    case 15:
      return TypeAdvertEnum.CrossMaterialDelivery
    case 4:
      return TypeAdvertEnum.MaterialsWebPage
    case 3:
      return TypeAdvertEnum.MaterialsWebPage
    case 1:
      return TypeAdvertEnum.PrimaryShopping
    case 2:
      return TypeAdvertEnum.SecondaryShopping
    case 5:
      return TypeAdvertEnum.CrossMaterialShopping
    case 6:
      return TypeAdvertEnum.CrossMaterialShopping
    case 7:
      return TypeAdvertEnum.CrossMaterialShopping

    default:
      return null
  }
}
function getAnnouncementsSortedByType(announcementInitial: Ad[]) {
  const typesAnnouncements: TypesAnnouncements = {
    primaryShopping: [],
    secondaryShopping: [],
    primaryDelivery: [],
    secondaryDelivery: [],
    crossMaterialDelivery: [],
    crossMaterialShopping: []
  }
  const announcement = announcementInitial.filter(
    (element) => element.location !== null
  )

  if (announcement.length > 0) {
    typesAnnouncements.primaryDelivery = announcement.filter(
      (element) =>
        getAdvertType(element.location!) === TypeAdvertEnum.PrimaryDelivery
    )
    typesAnnouncements.secondaryDelivery = announcement.filter(
      (element) =>
        getAdvertType(element.location!) === TypeAdvertEnum.SecondaryDelivery
    )
    typesAnnouncements.primaryShopping = announcement.filter(
      (element) =>
        getAdvertType(element.location!) === TypeAdvertEnum.PrimaryShopping
    )
    typesAnnouncements.secondaryShopping = announcement.filter(
      (element) =>
        getAdvertType(element.location!) === TypeAdvertEnum.SecondaryShopping
    )
    typesAnnouncements.crossMaterialDelivery = getAnnouncementsAcrossMaterials(
      announcement,
      TypeAdvertEnum.CrossMaterialDelivery
    )
    typesAnnouncements.crossMaterialShopping = getAnnouncementsAcrossMaterials(
      announcement,
      TypeAdvertEnum.CrossMaterialShopping
    )
  }

  return typesAnnouncements
}

function getAnnouncementsAcrossMaterials(
  announcement: Ad[],
  type: TypeAdvertEnum
) {
  const { groupBy } = useMenuModel()
  const announcementCrossMaterialNotGrouped = announcement.filter(
    (element) => getAdvertType(element.location!) === type
  )
  const announcementCrossMaterialNotGroupedByLocationMapped = groupBy(
    announcementCrossMaterialNotGrouped,
    (obj) => obj.location
  )
  return Array.from(
    announcementCrossMaterialNotGroupedByLocationMapped.values()
  )
}
