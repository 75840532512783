/* eslint-disable no-use-before-define */

import moment from 'moment-timezone'
import { usePlacesStore } from '~/store/places'
const billStates = {
  0: 'canceled',
  1: 'new',
  2: 'in_progress',
  3: 'prepared',
  4: 'delivered',
  5: 'shipping',
  10: 'accepted',
  11: 'rejected'
} as any
const shippingStates = {
  0: 'store_pickup',
  1: 'delivery_by_mevcut',
  2: 'shipping_by_store',
  3: 'external_shipping'
} as any
export type MaterialImage = {
  id: number
  image: string
  original_image: string
  thumb: string
}
export type UnitMaterial = {
  name: string
  rate: string
}
export type StoresSetting = {
  id: number
  key: string
  name: string
  pivot?: {
    value: string
  }
}
export type MaterialMark = {
  id: number
  name: string
}
export type AgentPlace = {
  id: number
  created_at?: string
  updated_at: string
  start_date: string
  title?: string
  details?: string
  LocationAddress?: LocationModel
  text_address?: string
  json_address?: string
  agent_id?: number
  city_id?: number
  street?: string
  building?: string
  floor?: string
  apartment?: string
  postal_code?: string
  city?: {
    id: number
    name: string
    state_id?: number
  }
}
function extractJsonAddress(place: AgentPlace): LocationModel | undefined {
  try {
    if (place.json_address && place.json_address !== 'null') {
      const parsedJsonAddress = JSON.parse(place.json_address)
      if (
        parsedJsonAddress &&
        typeof parsedJsonAddress.lat === 'number' &&
        typeof parsedJsonAddress.lng === 'number'
      ) {
        return parsedJsonAddress as LocationModel
      }
    }
  } catch (error) {}
  return undefined
}
export type ShippingWay = {
  deliveryCost?: number
  deliveryTime?: number
  disable?: boolean
  title: string
  message?: string
  type: string
  agentPlace?: AgentPlace
}
export type Balance = {
  local_credit?: string
  currency?: Currency
  id: number
}
export type PackageStore = {
  id: number
  name?: string
  support_statistics?: number
  allow_own_categories?: number
  allow_multi_currency?: number
  appear_in_public_platform?: number
  has_own_url?: number
  allow_ads?: number
  support_user_login?: number
  allow_offers?: number
  support_whats_app_invoices?: number
  support_branches?: number
}
export type ActivePackageStore = {
  id: number
  store_id?: number
  package_id?: number
  package?: PackageStore
}
export type City = {
  id: number
  name?: string
  state_id?: number
  payment_ways?: string | payWay[]
}
export type payWay = {
  iban: string
  currency?: number | string
  currency_id?: number | string
  name: string
  account_owner_name: string
}
export type Offer = {
  id: number
  material_id: number
  price: number
  new_price: number
  end_date: string
}
export type OptionPropertiesLookup = {
  id: number
  value: string
  created_at?: string
  image?: string
  affect_price?: boolean
  description?: string
  property_lookup_value_id?: number
  affect_price_or_quantity?: number
  property_id?: number
  material_id?: number
}

export type Definition = {
  label: string
  property_id: number
  type?: string
  unit?: string
  definition_id?: number
  visible_in_filters?: number
  single_for_agent?: number
  required_for_agent?: number
  id?: number
  propertiesListValues: PropertiesLookupValue[]
  options: OptionPropertiesLookup[]
}
export type PropertiesLookupValue = {
  id: number
  property_id: number
  property_definition_id?: number
  value_id: number
  owner_id: number

  definition?: Definition
  option?: OptionPropertiesLookup
}
export type OptionOtherDynamic = {
  id?: number
  name?: string
  summary?: string
  available?: boolean
  visible?: boolean
  buying_details?: string
  properties_lookup_values?: PropertiesLookupValue[]
  material_property_lookup_values?: PropertiesLookupValue[]
}

export type Currency = {
  id: number
  name: string
  code: string
  iso: string
}

export type ItemStoreCart = {
  id: number
  branchId?: number
  store: Store
  cartItems?: CartItem[]
  branch?: BranchStore
  items?: {
    withOutOffers?: CartItem[]
    withOffers?: ItemsCartWithOffers
  }
}
export type ItemsCartWithOffers = Record<string, CartItem[]>

export type Addons = {
  id: string
  quantity: number
  name?: string
  desc?: string
  price?: string
  original_price?: string
  max_amount?: number
  cost: number
  pivot?: {
    cart_item_id: number
    addon_id: number
    quantity: number
  }
}

export type BranchStore = {
  id: number
  name?: string
  address?: string
  city_id?: number
  phone?: string
  support_delivery: number
  store_id: number
  location?: { lat: string; lng: string }
}
export interface PropertiesLookupValuesCustom {
  propertiesLookupValuesEffectPricesOriginal: PropertiesLookupValue[] | []
  propertiesLookupValuesNormal: PropertiesLookupValue[] | []
  allPropertiesLookupValuesEffectPrices: PropertiesLookupValue[] | []
  propertiesLookupValuesEffectPricesFromOtherOptions:
    | PropertiesLookupValue[]
    | []
}
export interface Material {
  id: number
  name: string
  code: string
  barcode: string
  group_id?: number
  codeCurrency?: string
  branches?: BranchStore[]
  unit_information?: UnitMaterial[]
  simple_unit_style: boolean
  branch_id?: number
  store_id?: number
  summary?: string
  mark?: MaterialMark
  price: number
  finalPrice: number
  is_featured: boolean
  average_rate?: number
  rate_count?: number
  visible: boolean
  table_price_addition?: number | string
  table_price?: string
  otherOptions?: OptionOtherDynamic[]
  first_property_lookup_value?: PropertiesLookupValue
  available: boolean
  view_number?: number
  currency_id?: number
  definition_id?: number
  hasFavoritedByAgent?: boolean
  has_favorited?: boolean
  user_id?: number
  link?: string
  link_to_original?: string
  last_synced?: string
  approved: boolean
  extra_attributes?: { name: string; desc: string }[]
  propertiesLookupValuesCustom?: PropertiesLookupValuesCustom
  properties_lookup_values?: PropertiesLookupValue[]
  unit?: string
  step?: number
  min_quantity?: number
  addons: Addons[]
  paid_extra_attributes_amount?: number
  offers?: Offer[]
  offer?: Offer
  discount?: number
  discountRate?: string
  discountPrice?: string
  discount_amount?: number
  images: MaterialImage[]
  likeMaterials?: Material[]
  image: string
  thumb: string
  pivot?: {
    material_group_offer_id: number
    material_id: number
    material_quantity: number
  }
  stringPrice: string
  currencyCode?: string

  store?: Store
  count: number
  currencyIsRtl?: boolean
  video?: {
    id: string
    url: string
    image: string
    title: string
    provider: string
  }
  currency: Currency
}

export type Table = {
  store_id?: string | undefined
  table_id?: string | undefined
  branch_id?: any
  table_number?: string | undefined
}
export type User = {
  id: number
  email: string
  phone: string
  agent: {
    id: number
    first_name: string
    last_name: string
    profile_image?: string
    code?: string
    last_agent_place_id?: number
  }
}
export type Rate = {
  profile_thumb: MaterialImage
  id: number
  rating: number
  full_name_user?: string
  rateable_type: string
  rateable_id: number
  note: string
  user: User
}

export type Category = {
  id: number
  name: string
  description?: string
  image: string
  parent_id?: number
  children_count?: number
  materials?: Material[]
  offer?: Offer
  children?: Category[]
  ancestors?: Category[]
}
export type MaterialDefinition = {
  id: number
  name?: string
  type_id?: number
  properties?: Definition[]
}
export type StoreType = {
  id: number
  name: string
  pattern?: number
  patternStore?: PatternStoreType
  parent_id?: number
  image: string
  parent_categories?: Category[]
  material_definitions?: MaterialDefinition[]
  children?: StoreType[]
}
export type LocationModel = { lat: number; lng: number }
export enum PatternStoreType {
  Delivery = 'Delivery',
  Shipping = 'Shipping'
}
export enum TypePlaceEnum {
  House = 'house',
  Work = 'work',
  Other = 'other'
}
export enum TypeAdvertEnum {
  PrimaryShopping = 'primaryShopping',
  SecondaryShopping = 'secondaryShopping',
  PrimaryDelivery = 'primaryDelivery',
  SecondaryDelivery = 'secondaryDelivery',
  MaterialsWebPage = 'materialsWebPage',
  CrossMaterialDelivery = 'crossMaterialDelivery',
  CrossMaterialShopping = 'crossMaterialShopping'
}
export enum MoneyMovementTypes {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  unknown = 'unknown',
  bill = 'bill'
}
export enum MoneyMovementStatus {
  unknown = 'unknown',
  credit = 'credit',
  debit = 'debit'
}
export type MoneyMovement = {
  id?: number
  related_type?: string
  related_id?: number
  debit?: number | string
  credit?: number | string
  date: string
  balance?: number | string
  note?: string
  type?: MoneyMovementTypes
  status?: MoneyMovementStatus
  name?: string
  amount?: number
  image?: string
  posting_date?: string
  owner_type?: string
  owner_id?: number
  created_at?: string
  updated_at?: string
  currency: Currency
}
export type Store = {
  id: number
  support_filters?: number
  isStoreAvailableToBuy?: number | false
  city_id?: number
  slug: string
  name: string
  category_style?: number
  typeIsDelivering?: boolean
  pattern?: PatternStoreType
  branches?: BranchStore[]
  branchesByPattern?: BranchStore[]
  enableBranches?: number
  related_stores?: Store[]
  is_user_have_table?: boolean
  table_fixed_addition?: number
  table_percentage_addition?: number
  is_fixed_table_addition?: number
  about?: string
  supports_sila_delivery_for_admin?: number
  supports_sila_delivery_for_provider?: number
  image: string
  send_with_material_code?: number
  send_with_material_url?: number
  allowed_languages: string[]
  default_lang?: string
  thumb: string
  smallThumb: string
  largeThumb: string
  bg_image?: string
  color?: string
  shipping_details?: string
  isStoreSupportsPrivateDeliveryWithPrice?: boolean
  pay_ways?: string | payWay[]
  pay_on_door?: number
  pay_by_card?: number
  pay_by_transfer?: number
  show_most_requested_materials?: number
  show_newest_materials?: number
  show_most_viewed_materials?: number
  detailed_address?: string
  icon: string
  time_open: string
  time_close: string
  available_to_buy: number
  platform_hint?: string
  is_featured: boolean
  materials?: Material[]
  images?: { id: number; image: string; store_id: number }[]
  location?: LocationModel
  type_id: number
  cart_visible?: number
  domain?: string
  type?: StoreType
  active_package?: ActivePackageStore
  city?: City
  bg_color: string
  text_color: string
  bg_alt_color: string
  nav_color: string
  button_color: string
  text_alt_color: string
  use_custom_colors: boolean
  price_visible?: number
  whats_app_number?: string
  facebook?: string
  facebook_info?: string
  instagram?: string
  tikTok?: string
  telegram?: string
  twitter?: string
  youtube?: string
  whats_app_channel?: string
  snapchat?: string
  owner_phone?: string
  whatsapp_order: number
  delivery_duration?: number
  store_email?: string
  delivery_cost?: string
  minimum_price_for_zero_delivery_cost?: string
  support_delivery?: number
  lounge_phone?: string
  cart_hint?: string
  display_currency?: Currency
  first_image?: {
    id?: number
    image: string
    store_id?: number
  }
  one_line_material?: boolean
}
export type GroupOffer = {
  id: number
  offer_number: string
  is_expired?: boolean
  start_date: string
  start_date_local?: string
  end_date_local?: string
  end_date: string
  price: string
  priceNumber: number
  originalPrice: string
  discountRate: string
  currencyCode?: string
  currencyIsRtl?: boolean
  is_active: number
  store?: Store
  store_id: number
  created_at: string
  updated_at: string
  image: string
  materials?: Material[]
}
export type BillDetail = {
  id: number
  quantity: number
  addonsPrice?: number
  summary: string
  quantityByUnit?: string
  store?: Store
  material_group_offer_id?: number
  group_offer?: GroupOffer
  group_offer_uuid?: string
  stringDate?: string
  currency?: Currency
  hasOffer?: boolean
  stringPrice?: string
  stringOriginalPrice?: string
  stringState?: string
  allDefinitionsLookupValues?: Definition[]
  properties_lookup_values?: PropertiesLookupValue[]
  addons?: Addons[]
  state: number
  price: number
  fullPrice?: number
  stringfullPrice?: string
  original_price: number
  material: Material
}
export type UserOrder = {
  id: number
  bill_number: number
  branch_id?: number
  Paid_Amount: number
  state: number
  store?: Store
  pay_state: number
  note?: string
  averageRating?: string
  rating?: number
  stringDate?: string
  stringState?: string
  paySatus?: string
  currency?: Currency
  stringPaidAmount?: string
  stringDetailsAmount?: string
  stringShippingAmount?: string
  stringDiscountAmount?: string
  cancel_reason?: string
  agent_place_id: number
  head_delivering_bill_id: number
  created_at: string
  shipping_details?: string
  payment_details?: string
  store_id: number
  bill_details_count: number
  payment_type: number
  delivering_code?: string | number
  delivery_method: number
  original_amount?: number
  discount_amount?: string | number
  shipping_amount?: string | number
  bill_details_withOut_offers?: BillDetail[]
  bill_details_offers?: any
  branch?: BranchStore
  bill_details?: BillDetail[]
}

export type Ad = {
  id: number
  location: number
  position: number
  background?: string
  start_date: string
  end_date: string
  type: string
  title: string
  sub_title?: string
  button_title?: string
  image?: string
  logo?: string
  link?: string
  related_type?: string
  related_id?: number
  created_at: string
  updated_at: string
  related?: Record<string, any>
}
export type TypesAnnouncements = {
  primaryShopping?: Ad[]
  secondaryShopping?: Ad[]
  primaryDelivery?: Ad[]
  secondaryDelivery?: Ad[]
  crossMaterialDelivery?: Ad[][]
  crossMaterialShopping?: Ad[][]
}
export type CartItem = {
  id: number
  material: Material
  material_group_offer_id?: number
  group_offer_uuid?: string
  material_id: number
  parent_id?: number
  fullOfferPrice?: number
  fullOfferPriceString?: string
  fullOrginalPrice?: number
  fullOrginalPriceString?: string
  branch_id?: number
  branch?: BranchStore
  addons?: Addons[]
  allDefinitionsLookupValues?: Definition[]
  properties_lookup_values?: PropertiesLookupValue[]
  propertiesLookupValues?: any
  addonPrice?: number
  material_group_offer?: GroupOffer
  quantity: number
  quantityByUnit?: string
}

export const useStringPrice = (price: number, code?: string, digits = 2) => {
  return `${Intl.NumberFormat('en-UK', {
    useGrouping: false,
    maximumFractionDigits: digits
  }).format(price)} ${code || ''}`.trim()
}
const makeThumb = (image: string, width = 256) => {
  const { url } = useDomainHost()
  if (image === '/images/placeholder.webp') {
    return url + image
  }

  return `${url}/_cache_ipx/fit_cover&f_webp&q_90&loading_lazy&w_${width}/${image}`
}

export const useHttpUrl = (url?: string | null | undefined) => {
  if (!url || typeof url !== 'string') {
    return null
  }

  if (url.startsWith('http:') || url.startsWith('https:')) {
    return url
  }

  return useStorageUrl(url)
}

export const useIsRtl = () => {
  const { localeProperties } = useI18n()
  return localeProperties.value.dir?.toString() === 'rtl'
}

export const useCategoryStyle = () => {
  let categoryStyleTemp = 0
  /* استخدام البنية الشجرية افتراضيا */
  const isDomain = useIsDomain()
  if (isDomain) {
    const { store } = useDomainState()
    /* في حال كان الموقع دومين ويدعم بنية السطر الواحد استخدامها */

    categoryStyleTemp = store.value?.category_style || 0
  }
  return categoryStyleTemp
}
export default function useMenuModel() {
  const storageUrl = useStorageUrl('/')
  const route = useRoute()

  function rateModel(rate: Rate) {
    if (rate.profile_thumb?.image?.startsWith(storageUrl)) {
      return rate
    }

    if (rate.user?.agent?.profile_image) {
      const elementTemp = storageUrl + rate.user.agent.profile_image
      rate.user.agent.profile_image = elementTemp

      rate.profile_thumb = {
        image: elementTemp,
        id: rate.id,
        thumb: makeThumb(elementTemp)
      }
    } else {
      rate.profile_thumb = {
        image: '/images/placeholder.webp',
        id: rate.id,
        thumb: makeThumb('/images/placeholder.webp')
      }
    }

    rate.full_name_user = `${rate.user?.agent?.first_name || ''}
      ${rate.user?.agent?.last_name || ''}`.trim()

    return rate
  }

  function isListPropertiesLookupValuesHasPropertyLookupValue(
    list: PropertiesLookupValue[],
    property: PropertiesLookupValue
  ): boolean {
    return (
      list.find((element) => element.property_id === property.property_id) !==
      undefined
    )
  }

  function groupBy<T, K>(list: T[], getKey: (item: T) => K): Map<K, T[]> {
    const map = new Map<K, T[]>()
    list.forEach((item) => {
      const key = getKey(item)
      const collection = map.get(key)
      if (collection) {
        collection.push(item)
      } else {
        map.set(key, [item])
      }
    })
    map.forEach((value, key) => {
      const cleanedValue = removeCircularReferences(value)
      map.set(key, cleanedValue)
    })
    return map
  }

  function removeCircularReferences(obj: any, seen: any[] = []): any {
    if (obj && typeof obj === 'object') {
      if (seen.includes(obj)) {
        return null
      }

      seen.push(obj)

      const newObj: any = Array.isArray(obj) ? [] : {}

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = removeCircularReferences(obj[key], seen.slice())
        }
      }

      return newObj
    }

    return obj
  }

  function propertiesLookupValuesModel(material: Material) {
    const propertiesLookupValuesCustom = {
      propertiesLookupValuesEffectPricesOriginal: [] as PropertiesLookupValue[],
      allPropertiesLookupValuesEffectPrices: [] as PropertiesLookupValue[],
      propertiesLookupValuesEffectPricesFromOtherOptions:
        [] as PropertiesLookupValue[],
      propertiesLookupValuesNormal: [] as PropertiesLookupValue[]
    } as PropertiesLookupValuesCustom
    /* قائمة تحتوي على جميع  خيارات القيم المؤثرة على السعر من المواد الأخرى */
    propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesFromOtherOptions =
      Object.values(material.otherOptions ?? []).flatMap(
        (e) => e.material_property_lookup_values ?? []
      )

    /* قائمة تحتوي على جميع  خيارات القيم الغير مؤثرة على السعر في المادة الأًصلية */
    propertiesLookupValuesCustom.propertiesLookupValuesNormal = (
      material.properties_lookup_values ?? []
    ).filter(
      (propertyLookupValue) =>
        !isListPropertiesLookupValuesHasPropertyLookupValue(
          propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesFromOtherOptions,
          propertyLookupValue
        )
    ) as PropertiesLookupValue[]
    /* قائمة تحتوي على جميع  خيارات القيم  المؤثرة على السعر في المادة الأًصلية */
    propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesOriginal = (
      material.properties_lookup_values ?? []
    ).filter((propertyLookupValue) =>
      isListPropertiesLookupValuesHasPropertyLookupValue(
        propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesFromOtherOptions,
        propertyLookupValue
      )
    ) as PropertiesLookupValue[]
    /* قائمة تحتوي على جميع خيارات القيم  المؤثرة على السعر سواء في المادة الأًصلية أو من المواد الأخرى */
    propertiesLookupValuesCustom.allPropertiesLookupValuesEffectPrices = [
      ...propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesOriginal,
      ...propertiesLookupValuesCustom.propertiesLookupValuesEffectPricesFromOtherOptions
    ]

    return propertiesLookupValuesCustom
  }
  function convertListPropertiesLookupToListDefinition(
    propertiesLookupValues: PropertiesLookupValue[] | undefined,
    getKey: (item: any) => any,
    materialDefinitionCached?: MaterialDefinition[]
  ) {
    let allDefinitionsTemp = [] as Definition[]
    if (propertiesLookupValues?.length) {
      const allPropertiesLookupValueGroupedByDefinitionID = groupBy(
        propertiesLookupValues,
        getKey
      )

      for (const list of allPropertiesLookupValueGroupedByDefinitionID.values()) {
        let definitionTemp = list[0].definition
        if (definitionTemp) {
          /* في حال وجود تعاريف مواد مكيشة هذا يعني أنها تحتوي على معلومات كاملة ولذلك نحاول
          نأخذ هذه المعلومات  */
          if (materialDefinitionCached?.length) {
            const definitionMaterial: MaterialDefinition | undefined =
              materialDefinitionCached?.find(
                (el: MaterialDefinition) =>
                  el.id.toString() === definitionTemp?.definition_id?.toString()
              )

            definitionTemp =
              definitionMaterial?.properties?.find(
                (el) => el?.id?.toString() === definitionTemp?.id?.toString()
              ) || definitionTemp
          }
          definitionTemp.propertiesListValues = [...list]

          definitionTemp.propertiesListValues = useSortPropertyLookupValues(
            definitionTemp.propertiesListValues
          )

          allDefinitionsTemp.push(definitionTemp)
        }
      }
      allDefinitionsTemp = removeCircularReferences(allDefinitionsTemp)
    }
    return allDefinitionsTemp
  }
  function priceMaterialModel(material: Material) {
    const { store } = useDomainState()

    const priceMaterialWithTable = parseFloat(`${material.table_price}`)
    if (
      store.value?.is_user_have_table &&
      !isNaN(priceMaterialWithTable) &&
      priceMaterialWithTable > 0
    ) {
      material.finalPrice = priceMaterialWithTable
    } else {
      material.finalPrice = material.price
    }
    if (material.offers?.length) {
      material.offer = {
        id: material.offers[0].id,
        material_id: material.offers[0].material_id,
        price: material.offers[0].new_price,
        new_price: material.offers[0].new_price,
        end_date: material.offers[0].end_date
      }
      const newPrice =
        material.offer.new_price + (material.paid_extra_attributes_amount ?? 0)
      material.discount = newPrice
      material.discount_amount = material.finalPrice - newPrice
      material.discountRate = (
        (material.discount_amount * 100) /
        material.finalPrice
      ).toFixed(2)
      material.discountPrice = useStringPrice(newPrice, material.currencyCode)
    }
    material.stringPrice = useStringPrice(
      material.finalPrice,
      material.currencyCode
    )
    return material
  }
  function getPatternStoreTypeByValue(value?: number): PatternStoreType {
    switch (value) {
      case 0:
        return PatternStoreType.Delivery
      case 1:
        return PatternStoreType.Shipping
      default:
        return PatternStoreType.Shipping
    }
  }
  function materialModel(material: Material) {
    if (material.image?.startsWith(storageUrl)) {
      return material
    }

    if (!material.properties_lookup_values?.length) {
      material.properties_lookup_values = []
    }

    material.propertiesLookupValuesCustom =
      propertiesLookupValuesModel(material)
    const currency =
      material.codeCurrency || material.store?.display_currency?.code
    if (currency) {
      material.currencyIsRtl = checkRTL(currency)
      material.currencyCode = currency ?? ''
    }

    const imgProp = useIsDomain() ? 'original_image' : 'image'

    material.images = material.images?.map((el) => {
      if (el?.[imgProp]) {
        el.image = storageUrl + el[imgProp]
        el.thumb = makeThumb(el[imgProp])
      }
      return el
    })

    if (material.images?.length) {
      material.image = material.images[0].image || '/images/placeholder.webp'
    } else {
      material.image = '/images/placeholder.webp'
    }
    material.thumb = makeThumb(material.image)

    if (material.addons?.length) {
      material.addons?.forEach((el) => {
        el.cost = parseFloat(el.price || '0')
        el.price = useStringPrice(el.cost, material.currencyCode)
      })
    } else {
      material.addons = []
    }

    if (material.store) {
      material.store = storeModel(material.store)
    }
    material = priceMaterialModel(material)

    if (material.likeMaterials?.length) {
      material.likeMaterials = material.likeMaterials.map(materialModel)
    }
    if (material.unit) {
      material.unit = translateUnitToAr(material.unit)
    }
    material.unit_information = [
      { name: material.unit ?? '', rate: '1' },
      ...(material.unit_information || [])
    ]
    return material
  }
  function translateUnitToAr(unit: string) {
    const locale = useNuxtApp().$i18n?.locale?.value
    if (locale === 'ar') {
      const tranlation: Record<string, string | undefined> = {
        kg: 'كيلو',
        ounce: 'أوقية',
        g: 'غرام',
        meter: 'متر',
        cm: 'سم',
        l: 'لتر',
        ml: 'مل',
        box: 'صندوق',
        piece: 'قطعة'
      }
      return tranlation[unit] || unit
    }
    return unit
  }
  function billModel(bill: UserOrder) {
    if (bill.state != null && bill.state !== undefined) {
      bill.stringState = billStates[bill.state]
    }
    if (bill.pay_state === 1) {
      bill.paySatus = 'paid'
    } else {
      bill.paySatus = 'un_paid'
    }
    if (bill.averageRating) {
      bill.rating = parseFloat(bill.averageRating)
    }
    if (bill.delivery_method != null && bill.delivery_method !== undefined) {
      bill.shipping_details = shippingStates[bill.delivery_method]
    }
    if (bill.store) {
      bill.store = storeModel(bill.store)
    }
    if (bill.bill_details?.length) {
      if (!bill.currency?.code) {
        bill.currency = bill.bill_details[0].currency
      }
      if (!bill.store) {
        bill.store = bill.bill_details[0].material.store
      }
    }

    if (bill.created_at != null && bill.created_at !== undefined) {
      bill.stringDate = moment(bill.created_at)
        .locale('en')
        .format('(hh:mm a) YYYY/MM/DD')
        .toString()
    }

    if (bill.Paid_Amount != null && bill.Paid_Amount !== undefined) {
      bill.stringPaidAmount = `${useStringPrice(
        bill.Paid_Amount,
        bill.currency?.code
      )}`
    }
    if (bill.shipping_amount != null && bill.shipping_amount !== undefined) {
      bill.stringShippingAmount = `${useStringPrice(
        parseFloat(bill.shipping_amount!.toString()),
        bill.currency?.code
      )}`
    }
    if (bill.discount_amount != null && bill.discount_amount !== undefined) {
      bill.discount_amount = parseFloat(bill.discount_amount!.toString())
      bill.stringDiscountAmount = `${useStringPrice(
        bill.discount_amount,
        bill.currency?.code
      )}`
    } else {
      bill.discount_amount = 0
    }
    bill.bill_details?.forEach((detail) => {
      if (detail.material) {
        detail.material.codeCurrency = bill.currency?.code
        detail.material = materialModel(detail.material)
      }
      detail.allDefinitionsLookupValues =
        convertListPropertiesLookupToListDefinition(
          detail.properties_lookup_values,
          (obj: PropertiesLookupValue) => obj.definition?.id
        )

      if (detail.state != null && detail.state !== undefined) {
        detail.stringState = billStates[detail.state]
      }
      if (detail.group_offer) {
        detail.group_offer!.currencyCode = bill.currency?.code
        detail.group_offer = groupOfferModel(detail.group_offer)
      }
      if (detail.addons?.length) {
        detail.addons?.forEach((el) => {
          el.cost = parseFloat(el.price || '0')
          el.price = useStringPrice(el.cost, bill.currency?.code)
        })
        detail.addonsPrice = detail.addons.reduce(
          (r: number, attribute: Addons) => {
            r += (attribute.pivot?.quantity ?? 0) * attribute.cost
            return r
          },
          0.0
        )
      }
      if (
        detail.original_price != null &&
        detail.original_price !== undefined
      ) {
        detail.stringOriginalPrice = `${useStringPrice(
          detail.original_price,
          bill.currency?.code
        )}`
      }
    })
    bill.bill_details_withOut_offers = bill.bill_details?.filter(
      (item) =>
        item.material_group_offer_id === undefined ||
        item.material_group_offer_id === null
    )
    bill.bill_details_withOut_offers?.forEach((detail) => {
      if (detail.price != null && detail.price !== undefined) {
        detail.fullPrice =
          (detail.quantity ?? 0) * (detail.price ?? 0) +
          (detail.addonsPrice ?? 0)
        detail.stringfullPrice = `${useStringPrice(
          detail.fullPrice,
          bill.currency?.code
        )}`
        detail.hasOffer = detail.original_price !== detail.price
        detail.stringPrice = `${useStringPrice(
          detail.price,
          bill.currency?.code
        )}`
      }
      if (!detail.material?.simple_unit_style) {
        detail.quantityByUnit = detail.material.unit_information?.find(
          (el) => el.rate === detail.quantity.toString()
        )?.name
      }
    })

    bill.bill_details_offers = bill.bill_details?.reduce(
      (r: any, a: BillDetail) => {
        if (a.group_offer_uuid) {
          if (r[a.group_offer_uuid]) {
            r[a.group_offer_uuid].push(a)
          } else {
            r[a.group_offer_uuid] = []
            r[a.group_offer_uuid].push(a)
          }
        }
        return r
      },
      {}
    )
    return bill
  }

  function storeModel(store: Store) {
    if (
      store.image?.startsWith(storageUrl) ||
      store.icon?.startsWith(storageUrl)
    ) {
      return store
    }

    const locale = useNuxtApp().$i18n?.locale?.value
    if (store.images?.length) {
      store.image = store.images[0].image
      store.images?.forEach((el) => {
        el.image = storageUrl + el.image
      })
    }
    store.branchesByPattern = [...(store.branches || [])]
    store.pattern = getPatternStoreTypeByValue(store.type?.pattern)
    store.typeIsDelivering = store.pattern === PatternStoreType.Delivery
    store.enableBranches =
      store?.active_package?.package?.support_branches &&
      store?.branches?.length
    if (store.related_stores?.length) {
      store.related_stores = store.related_stores.map(storeModel)
    }
    if (store.first_image?.image) {
      store.first_image.image = storageUrl + store.first_image.image
    }

    if (store.image) {
      store.image = storageUrl + store.image
    } else {
      store.image = store.first_image?.image || '/images/placeholder.webp'
    }
    store.thumb = makeThumb(store.image, 512)

    if (store.icon) {
      store.icon = storageUrl + store.icon
    } else {
      store.icon = '/images/placeholder.webp'
    }

    if (store.bg_image) {
      store.bg_image = storageUrl + store.bg_image
    }

    if (store.pay_ways && typeof store.pay_ways === 'string') {
      store.pay_ways = JSON.parse(store.pay_ways)
    }
    if (store.name && typeof store.name === 'object') {
      const keys = Object.keys(store.name)
      if (keys.includes(locale)) {
        store.name = store.name[locale]
      } else if (keys.length) {
        store.name = store.name[keys[0]]
      }
    }
    if (store.about && typeof store.about === 'object') {
      const keys = Object.keys(store.about)
      if (keys.includes(locale)) {
        store.about = store.about[locale]
      } else if (keys.length) {
        store.about = store.about[keys[0]]
      }
    }

    store.isStoreAvailableToBuy =
      moment().isBetween(
        moment(store.time_open, 'HH:mm:ss'),
        moment(store.time_close, 'HH:mm:ss')
      ) && store.available_to_buy
    store.isStoreSupportsPrivateDeliveryWithPrice = useIsDomain()
      ? (store.shipping_details || '').trim().length === 0
      : true
    store.materials = store.materials?.map(materialModel) as Material[]
    if (store.typeIsDelivering && store.branches?.length) {
      const { cityId } = usePlacesStore()
      store.branchesByPattern = store?.branches?.filter(
        (el: BranchStore) => el.city_id?.toString() === cityId
      )
    }

    return store
  }
  function getTypeMoneyMovement(relatedType: string): MoneyMovementTypes {
    switch (relatedType) {
      case 'App\\Models\\Bill':
        return MoneyMovementTypes.bill
      case 'App\\Models\\Deposit':
        return MoneyMovementTypes.deposit
      case 'App\\Models\\Withdrawal':
        return MoneyMovementTypes.withdrawal
      default:
        return MoneyMovementTypes.unknown
    }
  }
  function getStatusMoneyMovement(
    debit?: number,
    credit?: number
  ): MoneyMovementStatus {
    if (debit !== null && debit !== 0) {
      return MoneyMovementStatus.debit
    } else if (credit !== null && credit !== 0) {
      return MoneyMovementStatus.credit
    } else {
      return MoneyMovementStatus.unknown
    }
  }
  function cityModel(city: City) {
    if (city.payment_ways && typeof city.payment_ways === 'string') {
      city.payment_ways = JSON.parse(city.payment_ways)
    }
    return city
  }

  function moneyMovementModel(moneyMovement: MoneyMovement) {
    if (moneyMovement.date) {
      return
    }
    moneyMovement.debit = parseFloat(moneyMovement.debit?.toString() || '')
    moneyMovement.credit = parseFloat(moneyMovement.credit?.toString() || '')
    moneyMovement.balance = parseFloat(moneyMovement.balance?.toString() || '')
    moneyMovement.type = getTypeMoneyMovement(moneyMovement.related_type ?? '')
    moneyMovement.status = getStatusMoneyMovement(
      moneyMovement.debit,
      moneyMovement.credit
    )
    moneyMovement.amount =
      moneyMovement.debit === 0 ? moneyMovement.credit : moneyMovement.debit
    const formattedCreatedAt = moment(moneyMovement.created_at).format(
      'YYYY-MM-DD'
    )
    const formattedNow = moment().format('YYYY-MM-DD')
    moneyMovement.date =
      formattedCreatedAt === formattedNow ? 'today' : formattedCreatedAt
    return moneyMovement
  }

  function storeTypeModel(storeType: StoreType) {
    if (storeType.image?.startsWith(storageUrl)) {
      return storeType
    }

    if (storeType.image) {
      storeType.image = useStorageUrl('/' + storeType.image)
    } else {
      storeType.image = '/images/placeholder.webp'
    }
    storeType.patternStore = getPatternStoreTypeByValue(storeType?.pattern)

    if (storeType.parent_categories) {
      storeType.parent_categories = storeType.parent_categories?.map(
        categoryModel
      ) as Category[]
    }

    storeType.material_definitions = materialDfinitionsModel(
      storeType.material_definitions
    )

    if (storeType.children) {
      storeType.children = storeType.children?.map(
        storeTypeModel
      ) as StoreType[]
    }

    return storeType
  }

  function materialDfinitionsModel(materialDfinitions?: MaterialDefinition[]) {
    if (materialDfinitions?.length) {
      materialDfinitions?.map((item) => {
        item.properties =
          item.properties?.filter(
            (el) =>
              el.type?.includes('Multi') &&
              el?.options?.filter((el) => el.value?.trim()?.length)?.length
          ) ?? []
        return item
      })
    }
    return materialDfinitions
  }
  function placeModel(agentPlace: AgentPlace) {
    agentPlace.LocationAddress = extractJsonAddress(agentPlace)

    return agentPlace
  }

  function categoryModel(category: Category) {
    if (category.image?.startsWith(storageUrl)) {
      return category
    }

    if (category.image) {
      category.image = useStorageUrl('/' + category.image)
    } else {
      category.image = '/images/placeholder.webp'
    }

    if (category.materials) {
      category.materials = category.materials.map(materialModel) as Material[]
    }

    if (category.children) {
      category.children = category.children.map(categoryModel) as Category[]
    }

    if (category.ancestors) {
      category.ancestors = category.ancestors.map(categoryModel) as Category[]
    }

    return category
  }

  function adModel(ad: Ad) {
    if (ad.image?.startsWith(storageUrl)) {
      return ad
    }

    if (ad.image) {
      ad.image = useStorageUrl('/' + ad.image)
    } else {
      ad.image = '/images/placeholder.webp'
    }

    if (ad.logo) {
      ad.logo = useStorageUrl('/' + ad.logo)
    } else {
      ad.logo = '/images/placeholder.webp'
    }

    if (ad.background) {
      ad.background = useStorageUrl('/' + ad.background)
    } else {
      ad.background = '/images/placeholder.webp'
    }

    return ad
  }

  function groupOfferModel(groupOffer: GroupOffer) {
    if (groupOffer.image?.startsWith(storageUrl)) {
      return groupOffer
    }

    groupOffer.end_date_local = moment
      .utc(groupOffer.end_date)
      .local()
      .locale('ar')
      .format('(hh:mm a) MM/DD')
    groupOffer.start_date_local = moment
      .utc(groupOffer.start_date)
      .local()
      .locale('ar')
      .format('(hh:mm a) MM/DD')
    if (groupOffer.image) {
      groupOffer.image = useStorageUrl('/' + groupOffer.image)
    } else {
      groupOffer.image = '/images/placeholder.webp'
    }
    if (groupOffer.store) {
      groupOffer.store = storeModel(groupOffer.store)
    }
    const currencyCode = groupOffer.store?.display_currency?.code || ''
    groupOffer.priceNumber = Number(groupOffer.price)
    if (groupOffer.materials) {
      const originalPrice = groupOffer.materials.reduce(
        (r: number, a: Material, index) => {
          if (currencyCode) {
            groupOffer.currencyCode = currencyCode
            groupOffer.currencyIsRtl = checkRTL(currencyCode)
          }
          groupOffer.materials![index] = materialModel(a)

          r = r + a.price * (a.pivot?.material_quantity || 1)
          return r
        },
        0
      )
      if (originalPrice) {
        groupOffer.originalPrice = useStringPrice(originalPrice, currencyCode)
        groupOffer.discountRate = (
          ((originalPrice - groupOffer.priceNumber) * 100) /
          originalPrice
        ).toFixed(2)
      }
    }
    groupOffer.price = useStringPrice(
      parseFloat(groupOffer.price),
      groupOffer.currencyCode
    )
    return groupOffer
  }

  function cartModel(cartItem: CartItem) {
    if (cartItem.material?.image?.startsWith(storageUrl)) {
      return cartItem
    }

    cartItem.addonPrice = 0
    if (cartItem.material) {
      cartItem.material = materialModel(cartItem.material)
    }
    if (!cartItem.material?.simple_unit_style && !cartItem.group_offer_uuid) {
      cartItem.quantityByUnit = cartItem.material.unit_information?.find(
        (el) => el.rate === cartItem.quantity.toString()
      )?.name
    }
    if (cartItem.material_group_offer) {
      cartItem.material_group_offer!.currencyCode =
        cartItem.material.currencyCode
      cartItem.material_group_offer!.currencyIsRtl =
        cartItem.material.currencyIsRtl
      cartItem.material_group_offer = groupOfferModel(
        cartItem.material_group_offer
      )
    }

    if (cartItem.addons?.length) {
      if (cartItem.material.addons?.length) {
        cartItem.addonPrice = cartItem.addons.reduce(
          (r: number, attribute, index) => {
            attribute.quantity = attribute.pivot?.quantity || 1
            const priceItem = cartItem.material.addons.find(
              (elem) => elem.id === attribute.id
            )
            if (priceItem) {
              cartItem!.addons![index].desc = priceItem.desc
              cartItem!.addons![index].name = priceItem.name
              cartItem!.addons![index].price = priceItem.price
              cartItem!.addons![index].cost = priceItem.cost

              r =
                r +
                (attribute.pivot?.quantity ?? 0) * parseFloat(priceItem!.price!)
            }
            return r
          },
          0.0
        )
      }
    }
    cartItem.allDefinitionsLookupValues =
      convertListPropertiesLookupToListDefinition(
        cartItem.properties_lookup_values,
        (obj: PropertiesLookupValue) => obj.definition?.id
      )
    cartItem.fullOrginalPrice =
      cartItem.material.finalPrice * cartItem.quantity + cartItem.addonPrice
    cartItem.fullOrginalPriceString = useStringPrice(
      cartItem.fullOrginalPrice,
      cartItem.material.currencyCode
    )
    if (cartItem.material.offers?.length) {
      cartItem.fullOfferPrice =
        (parseFloat(cartItem.material.discountPrice!) || 0) *
          cartItem.quantity +
        cartItem.addonPrice
      cartItem.fullOfferPriceString = useStringPrice(
        cartItem.fullOfferPrice,
        cartItem.material.currencyCode
      )
    }
    return cartItem
  }

  function dateIsExpired(date?: string) {
    if (date) {
      const now = moment(new Date())
      return moment.utc(date).local().isBefore(now)
    }
    return false
  }

  function isAuthLoggedIn() {
    const { status } = useAuth()
    return status.value === 'authenticated'
  }
  function routeIsForMaterialPage() {
    const materialIdPattern = /^(\/\w+)?\/materials\/(\d+)$/
    return materialIdPattern.test(route.path)
  }
  return {
    materialModel,
    storeModel,
    routeIsForMaterialPage,
    cityModel,
    storeTypeModel,
    categoryModel,
    materialDfinitionsModel,
    billModel,
    removeCircularReferences,
    rateModel,
    propertiesLookupValuesModel,
    adModel,
    groupOfferModel,
    cartModel,
    placeModel,
    groupBy,
    moneyMovementModel,
    convertListPropertiesLookupToListDefinition,
    dateIsExpired,
    isAuthLoggedIn
  }
}
function checkRTL(word?: string) {
  const rtlRx = /[\u0591-\u07FF]/

  return word ? rtlRx.test(word) : undefined
}
export const useSortPropertyLookupValues = (
  propertiesListValues?: PropertiesLookupValue[]
) => {
  if (!propertiesListValues) {
    return []
  }

  // نسخ القائمة لمنع التعديل على القائمة الأصلية
  const propertiesListValuesTemp = [...propertiesListValues]

  // ترتيب حسب valueId
  propertiesListValuesTemp.sort((a, b) => {
    return (a.value_id ?? 0) - (b.value_id ?? 0)
  })

  // ترتيب حسب التاريخ باستخدام moment.js
  propertiesListValuesTemp.sort((a, b) => {
    const dateA = a.option?.created_at ? moment(a.option.created_at) : moment()
    const dateB = b.option?.created_at ? moment(b.option.created_at) : moment()

    return dateA.diff(dateB)
  })

  return propertiesListValuesTemp
}
