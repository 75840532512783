import type { RouterConfig } from '@nuxt/schema'
import type { RouteRecordRaw } from 'vue-router'
import type { StoreWithDomain } from '~/server/routes/nuxt-api/domains.get'

const routeReplacer = (route: RouteRecordRaw, search: string) => {
  return {
    ...route,
    path: route.path.replace(`/${search}`, ''),
    name: (route.name as string)?.replace(`${search}-`, ''),
    meta: {
      ...route.meta,
      layout: search
    }
  }
}

const filterRoutes = (
  routes: readonly RouteRecordRaw[],
  include: string,
  exclude: string
) => {
  return routes
    .filter((route) => !route.path.match(exclude))
    .map((route) => {
      if (route.path.includes(`/${include}`)) {
        return routeReplacer(route, include)
      }

      if (route.path.includes('/shared')) {
        return routeReplacer(route, 'shared')
      }

      return route
    })
}

let domains: string[] = []

export default <RouterConfig>{
  routes: (routes) => {
    if (import.meta.server) {
      useFetch<StoreWithDomain[]>('/nuxt-api/domains').then((data) => {
        domains = data.data.value?.map((el) => el.domain) || []
      })
    }

    const { layout } = useDomainHost(domains)

    // Select Platform Routes
    // if (layout === 'platform') {
    //   return filterRoutes(routes, 'platform', '(domain)')
    // }

    // Select Domain Routes
    if (layout === 'domain') {
      return filterRoutes(routes, 'domain', '(platform)')
    }

    // Not Valid Domain
    return [
      {
        path: '/:pathMatch(.*)*',
        component: () => import('~/components/ErrorPage.vue'),
        props: {
          error: {
            url: '/',
            message: 'page_not_found',
            statusCode: 404
          },
          translate: true
        },
        meta: {
          layout: false
        }
      }
    ]
  }
}
