import { default as _91id_93rYlyAOi47qMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/about/[id].vue?macro=true";
import { default as cartnG8cSYlN5yMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/cart.vue?macro=true";
import { default as categoriestXDlpkemacMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/categories.vue?macro=true";
import { default as indexkcE2rb7CHYMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/index.vue?macro=true";
import { default as _91id_939VBFRdzZJBMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/[id].vue?macro=true";
import { default as indexEXYkDuhCOjMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/index.vue?macro=true";
import { default as offersUH5MfhQwY6Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/offers.vue?macro=true";
import { default as email_45reset_45passwordATa49AUh2IMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/email-reset-password.vue?macro=true";
import { default as forgotxJFAsniSPhMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/forgot.vue?macro=true";
import { default as loginPOLxNV3Q0PMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/login.vue?macro=true";
import { default as phone_45reset_45passwordpgbbXpWKAsMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/phone-reset-password.vue?macro=true";
import { default as registersTORGdnkNmMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/register.vue?macro=true";
import { default as indexBzmVk7GGYdMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index.vue?macro=true";
import { default as _provider5Ls3SElHRPMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/social/_provider.vue?macro=true";
import { default as verify_45emailEAxLLed8agMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/verify-email.vue?macro=true";
import { default as editQFQd6QBMUdMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/edit.vue?macro=true";
import { default as indexrknHN10BqiMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/index.vue?macro=true";
import { default as invite_45friendsyc66AQcgJ7Meta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/invite-friends.vue?macro=true";
import { default as money_45movementsVKkw2PFE7FMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/money-movements.vue?macro=true";
import { default as notificationsEO8Dsh02AXMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/notifications.vue?macro=true";
import { default as _91id_93lDl9WR2TMFMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/[id].vue?macro=true";
import { default as indexFteLVSQPewMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/index.vue?macro=true";
import { default as places9LfVOag3CnMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/places.vue?macro=true";
import { default as wishlisti1Fx38tQxKMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/wishlist.vue?macro=true";
import { default as indexs8uz2QqSOTMeta } from "/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index.vue?macro=true";
export default [
  {
    name: "domain-about-id___en",
    path: "/en/domain/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/about/[id].vue")
  },
  {
    name: "domain-about-id___tr",
    path: "/tr/domain/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/about/[id].vue")
  },
  {
    name: "domain-about-id___ar",
    path: "/domain/about/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/about/[id].vue")
  },
  {
    name: "domain-cart___en",
    path: "/en/domain/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/cart.vue")
  },
  {
    name: "domain-cart___tr",
    path: "/tr/domain/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/cart.vue")
  },
  {
    name: "domain-cart___ar",
    path: "/domain/cart",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/cart.vue")
  },
  {
    name: "domain-categories___en",
    path: "/en/domain/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/categories.vue")
  },
  {
    name: "domain-categories___tr",
    path: "/tr/domain/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/categories.vue")
  },
  {
    name: "domain-categories___ar",
    path: "/domain/categories",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/categories.vue")
  },
  {
    name: "domain___en",
    path: "/en/domain",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/index.vue")
  },
  {
    name: "domain___tr",
    path: "/tr/domain",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/index.vue")
  },
  {
    name: "domain___ar",
    path: "/domain",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/index.vue")
  },
  {
    name: "domain-materials-id___en",
    path: "/en/domain/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials-id___tr",
    path: "/tr/domain/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials-id___ar",
    path: "/domain/materials/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials___en",
    path: "/en/domain/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/index.vue")
  },
  {
    name: "domain-materials___tr",
    path: "/tr/domain/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/index.vue")
  },
  {
    name: "domain-materials___ar",
    path: "/domain/materials",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/materials/index.vue")
  },
  {
    name: "domain-offers___en",
    path: "/en/domain/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/offers.vue")
  },
  {
    name: "domain-offers___tr",
    path: "/tr/domain/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/offers.vue")
  },
  {
    name: "domain-offers___ar",
    path: "/domain/offers",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/domain/offers.vue")
  },
  {
    name: "shared-auth___en",
    path: "/en/shared/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index.vue"),
    children: [
  {
    name: "shared-auth-index-email-reset-password___en",
    path: "email-reset-password",
    meta: email_45reset_45passwordATa49AUh2IMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/email-reset-password.vue")
  },
  {
    name: "shared-auth-index-forgot___en",
    path: "forgot",
    meta: forgotxJFAsniSPhMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/forgot.vue")
  },
  {
    name: "shared-auth-index-login___en",
    path: "login",
    meta: loginPOLxNV3Q0PMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/login.vue")
  },
  {
    name: "shared-auth-index-phone-reset-password___en",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordpgbbXpWKAsMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/phone-reset-password.vue")
  },
  {
    name: "shared-auth-index-register___en",
    path: "register",
    meta: registersTORGdnkNmMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/register.vue")
  }
]
  },
  {
    name: "shared-auth___tr",
    path: "/tr/shared/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index.vue"),
    children: [
  {
    name: "shared-auth-index-email-reset-password___tr",
    path: "email-reset-password",
    meta: email_45reset_45passwordATa49AUh2IMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/email-reset-password.vue")
  },
  {
    name: "shared-auth-index-forgot___tr",
    path: "forgot",
    meta: forgotxJFAsniSPhMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/forgot.vue")
  },
  {
    name: "shared-auth-index-login___tr",
    path: "login",
    meta: loginPOLxNV3Q0PMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/login.vue")
  },
  {
    name: "shared-auth-index-phone-reset-password___tr",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordpgbbXpWKAsMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/phone-reset-password.vue")
  },
  {
    name: "shared-auth-index-register___tr",
    path: "register",
    meta: registersTORGdnkNmMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/register.vue")
  }
]
  },
  {
    name: "shared-auth___ar",
    path: "/shared/auth",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index.vue"),
    children: [
  {
    name: "shared-auth-index-email-reset-password___ar",
    path: "email-reset-password",
    meta: email_45reset_45passwordATa49AUh2IMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/email-reset-password.vue")
  },
  {
    name: "shared-auth-index-forgot___ar",
    path: "forgot",
    meta: forgotxJFAsniSPhMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/forgot.vue")
  },
  {
    name: "shared-auth-index-login___ar",
    path: "login",
    meta: loginPOLxNV3Q0PMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/login.vue")
  },
  {
    name: "shared-auth-index-phone-reset-password___ar",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordpgbbXpWKAsMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/phone-reset-password.vue")
  },
  {
    name: "shared-auth-index-register___ar",
    path: "register",
    meta: registersTORGdnkNmMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/index/register.vue")
  }
]
  },
  {
    name: "shared-auth-social-_provider___en",
    path: "/en/shared/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/social/_provider.vue")
  },
  {
    name: "shared-auth-social-_provider___tr",
    path: "/tr/shared/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/social/_provider.vue")
  },
  {
    name: "shared-auth-social-_provider___ar",
    path: "/shared/auth/social/_provider",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/social/_provider.vue")
  },
  {
    name: "shared-auth-verify-email___en",
    path: "/en/shared/auth/verify-email",
    meta: verify_45emailEAxLLed8agMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/verify-email.vue")
  },
  {
    name: "shared-auth-verify-email___tr",
    path: "/tr/shared/auth/verify-email",
    meta: verify_45emailEAxLLed8agMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/verify-email.vue")
  },
  {
    name: "shared-auth-verify-email___ar",
    path: "/shared/auth/verify-email",
    meta: verify_45emailEAxLLed8agMeta || {},
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/auth/verify-email.vue")
  },
  {
    name: indexs8uz2QqSOTMeta?.name,
    path: "/en/shared/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index.vue"),
    children: [
  {
    name: "shared-my-account-index-edit___en",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/edit.vue")
  },
  {
    name: "shared-my-account-index___en",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/index.vue")
  },
  {
    name: "shared-my-account-index-invite-friends___en",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/invite-friends.vue")
  },
  {
    name: "shared-my-account-index-money-movements___en",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/money-movements.vue")
  },
  {
    name: "shared-my-account-index-notifications___en",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/notifications.vue")
  },
  {
    name: "shared-my-account-index-orders-id___en",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/[id].vue")
  },
  {
    name: "shared-my-account-index-orders___en",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/index.vue")
  },
  {
    name: "shared-my-account-index-places___en",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/places.vue")
  },
  {
    name: "shared-my-account-index-wishlist___en",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: indexs8uz2QqSOTMeta?.name,
    path: "/tr/shared/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index.vue"),
    children: [
  {
    name: "shared-my-account-index-edit___tr",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/edit.vue")
  },
  {
    name: "shared-my-account-index___tr",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/index.vue")
  },
  {
    name: "shared-my-account-index-invite-friends___tr",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/invite-friends.vue")
  },
  {
    name: "shared-my-account-index-money-movements___tr",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/money-movements.vue")
  },
  {
    name: "shared-my-account-index-notifications___tr",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/notifications.vue")
  },
  {
    name: "shared-my-account-index-orders-id___tr",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/[id].vue")
  },
  {
    name: "shared-my-account-index-orders___tr",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/index.vue")
  },
  {
    name: "shared-my-account-index-places___tr",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/places.vue")
  },
  {
    name: "shared-my-account-index-wishlist___tr",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: indexs8uz2QqSOTMeta?.name,
    path: "/shared/my-account",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index.vue"),
    children: [
  {
    name: "shared-my-account-index-edit___ar",
    path: "edit",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/edit.vue")
  },
  {
    name: "shared-my-account-index___ar",
    path: "",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/index.vue")
  },
  {
    name: "shared-my-account-index-invite-friends___ar",
    path: "invite-friends",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/invite-friends.vue")
  },
  {
    name: "shared-my-account-index-money-movements___ar",
    path: "money-movements",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/money-movements.vue")
  },
  {
    name: "shared-my-account-index-notifications___ar",
    path: "notifications",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/notifications.vue")
  },
  {
    name: "shared-my-account-index-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/[id].vue")
  },
  {
    name: "shared-my-account-index-orders___ar",
    path: "orders",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/orders/index.vue")
  },
  {
    name: "shared-my-account-index-places___ar",
    path: "places",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/places.vue")
  },
  {
    name: "shared-my-account-index-wishlist___ar",
    path: "wishlist",
    component: () => import("/home/runner/atlassian-bitbucket-pipelines-runner/temp/d914fb0b-0e8c-5f2d-9956-7686276e8459/build/pages/shared/my-account/index/wishlist.vue")
  }
]
  }
]